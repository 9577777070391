import styled from '@emotion/styled';
import { intersection, union } from 'lodash';
import { memo } from 'react';
import { useSnapshot } from 'valtio';
import { fill_horizontal_all_center, fill_horizontal_cross_center, fill_vertical_cross_center, jc, } from '~/modules/AppLayout/FlexGridCss';
import { useDailyRankResource } from '~/modules/screener/containers/useStockScreenerResource';
import { SimpleQuoteListInteractWithChart } from '~/modules/symbolQuote/simple';
import { useVirtualAccountOrderPre, useVirtualTransaction, useVirtualAccountHolding, } from '~/modules/virtualExchange/useVirtualExchangeResource';
import { staticStore } from '~/pages/heineken_template/_private/staticStore';
import { store } from '~/pages/heineken_template/_private/store';
import { asiajyeStore } from '../asiajyeStock_Sidebar';
import { Radio } from '@mantine/core';
import { useSortSignalrDataStore } from '~/modules/symbolQuote/simple/useSortStore';
export const ConditionPro = memo(function ConditionPro() {
    const state = useSnapshot(asiajyeStore);
    const lastTradeString = useSnapshot(staticStore).tradedDate.intraday.format('YYYY-MM-DD');
    //  初始化時使用原始標的array的排序（因為我再輸入標的前已經先排序過）
    useSortSignalrDataStore.sortKey = 'none';
    useSortSignalrDataStore.orderKey = 'none';
    const day = 3;
    // 當天進場，必定是前一天符合條件，所以要找4天內符合條件的標的，要找3天內進場的標的
    const positionBeginDate = useSnapshot(staticStore).tradedDate.days[day].format('YYYY-MM-DD');
    // 一定是少兩天的資料，因為不可能當天就出場，隔天有出場訊號，再下一天部位才會出場。如果日期定太早，反而會撈到進場之前的翻單交易資料
    const positionExitBeginDate = useSnapshot(staticStore).tradedDate.days[day - 2].format('YYYY-MM-DD');
    //選擇持有中的標的
    const holdingSymbolMacd = useVirtualAccountHolding('asiajye_stock_macd') ?? [];
    const holdingSymbolOriginal = useVirtualAccountHolding('asiajye_stock_original') ?? [];
    // 選出當天符合標準，隔天進場的多方標的
    const todaylongEntrySymbolMacd = useVirtualAccountOrderPre('asiajye_stock_macd', 'B', {
        dateTime: lastTradeString,
    })
        ?.sort((a, b) => new Date(b.dealDatetime).getTime() - new Date(a.dealDatetime).getTime())
        .map(a => a.symbol);
    const todaylongEntrySymbolOriginal = useVirtualAccountOrderPre('asiajye_stock_original', 'B', {
        dateTime: lastTradeString,
    })
        ?.sort((a, b) => new Date(b.dealDatetime).getTime() - new Date(a.dealDatetime).getTime())
        .map(a => a.symbol);
    // 選出當天符合標準，隔天進場的空方標的
    const todayShortEntrySymbolMacd = useVirtualAccountOrderPre('asiajye_stock_macd', 'S', {
        dateTime: lastTradeString,
    })
        ?.sort((a, b) => new Date(b.dealDatetime).getTime() - new Date(a.dealDatetime).getTime())
        .map(a => a.symbol);
    const todayShortEntrySymbolOriginal = useVirtualAccountOrderPre('asiajye_stock_original', 'S', {
        dateTime: lastTradeString,
    })
        ?.sort((a, b) => new Date(b.dealDatetime).getTime() - new Date(a.dealDatetime).getTime())
        .map(a => a.symbol);
    // !多方標的
    // 1. 4天內有符合asiajye_stock_macd和asiajye_stock_original的多方標的
    const nearDayslongEntrySymbolMacd = useVirtualTransaction('asiajye_stock_macd', {
        beginDatetime: positionBeginDate,
    })
        ?.filter(datum => datum.bs === 'B' && datum.positionType === 'O')
        .map(datum => datum.symbol);
    const nearDayslongEntrySymbolOriginal = useVirtualTransaction('asiajye_stock_original', {
        beginDatetime: positionBeginDate,
    })
        ?.filter(datum => datum.bs === 'B' && datum.positionType === 'O')
        .map(datum => datum.symbol);
    // 2.選出還有持有asiajye_stock_macd和asiajye_stock_original尚未出場的標的
    const holdingLongSymbolMacd = holdingSymbolMacd
        .filter(symbol => symbol.qty > 0)
        .sort((a, b) => new Date(b.lastModifiedDatetime).getTime() - new Date(a.lastModifiedDatetime).getTime())
        .map(a => a.symbol);
    const holdingLongSymbolOriginal = holdingSymbolOriginal
        .filter(symbol => symbol.qty > 0)
        // 從最新到晚進場依序排列
        .sort((a, b) => new Date(b.lastModifiedDatetime).getTime() - new Date(a.lastModifiedDatetime).getTime())
        .map(a => a.symbol);
    // 2-3 篩選出還有多方部位的標的
    const filteredNearDayslongEntrySymbolMacd = intersection(holdingLongSymbolMacd, nearDayslongEntrySymbolMacd);
    const filteredNearDayslongEntrySymbolOriginal = intersection(holdingLongSymbolOriginal, nearDayslongEntrySymbolOriginal);
    // 3. 今天兩個策略預計進場和之前進場還有部位的標的取得交叉聯集
    const todayLongEntrySymbolMacdWithOriginal = todaylongEntrySymbolMacd?.filter(symbol => filteredNearDayslongEntrySymbolOriginal?.includes(symbol));
    const todayLongEntrySymbolOriginalWithMacd = todaylongEntrySymbolOriginal?.filter(symbol => filteredNearDayslongEntrySymbolMacd?.includes(symbol));
    // 今天同時符合兩個策略進場
    const todayLongEntrySymbolOriginalAndMacd = todaylongEntrySymbolOriginal?.filter(symbol => todaylongEntrySymbolMacd?.includes(symbol));
    // 4. 兩個策略取聯集為當天進場標的
    const todayLongEntrySymbol = union(todayLongEntrySymbolOriginalAndMacd, todayLongEntrySymbolOriginalWithMacd, todayLongEntrySymbolMacdWithOriginal);
    // 5. 之前就進場標的取交集
    const nearDayslongEntrySymbol = intersection(filteredNearDayslongEntrySymbolOriginal, filteredNearDayslongEntrySymbolMacd);
    const longEntrySymbol = todayLongEntrySymbol.concat(nearDayslongEntrySymbol);
    // !空方標的
    // 1. 4天內有符合asiajye_stock_macd和asiajye_stock_original的空方標的
    const nearDaysShortEntrySymbolMacd = useVirtualTransaction('asiajye_stock_macd', {
        beginDatetime: positionBeginDate,
    })
        ?.filter(datum => datum.bs === 'S' && datum.positionType === 'O')
        .map(datum => datum.symbol);
    const nearDaysShortEntrySymbolOriginal = useVirtualTransaction('asiajye_stock_original', {
        beginDatetime: positionBeginDate,
    })
        ?.filter(datum => datum.bs === 'S' && datum.positionType === 'O')
        .map(datum => datum.symbol);
    // 2.asiajye_stock_macd和asiajye_stock_original尚未出場的標的
    // 2-1 選出還有持有asiajye_stock_macd和asiajye_stock_original的部位
    const holdingShortSymbolMacd = holdingSymbolMacd
        .filter(symbol => symbol.qty < 0)
        .sort((a, b) => new Date(b.lastModifiedDatetime).getTime() - new Date(a.lastModifiedDatetime).getTime())
        .map(a => a.symbol);
    const holdingShortSymbolOriginal = holdingSymbolOriginal
        .filter(symbol => symbol.qty < 0)
        // 從最新到晚進場依序排列
        .sort((a, b) => new Date(b.lastModifiedDatetime).getTime() - new Date(a.lastModifiedDatetime).getTime())
        .map(a => a.symbol);
    // 2-2 篩選出期間中還有空方部位的標的
    const filteredNearDaysShortEntrySymbolMacd = intersection(holdingShortSymbolMacd, nearDaysShortEntrySymbolMacd);
    const filteredNearDaysShortEntrySymbolOriginal = intersection(holdingShortSymbolOriginal, nearDaysShortEntrySymbolOriginal);
    // 3. 今天兩個策略預計進場和之前進場還有部位的標的取得交叉聯集
    const todayShortEntrySymbolMacdWithOriginal = todayShortEntrySymbolMacd?.filter(symbol => filteredNearDaysShortEntrySymbolOriginal?.includes(symbol));
    const todayShortEntrySymbolOriginalWithMacd = todayShortEntrySymbolOriginal?.filter(symbol => filteredNearDaysShortEntrySymbolMacd?.includes(symbol));
    // 今天同時符合兩個策略進場
    const todayShortEntrySymbolOriginalAndMacd = todayShortEntrySymbolOriginal?.filter(symbol => todayShortEntrySymbolMacd?.includes(symbol));
    // 4. 兩個策略取聯集
    const todayShortEntrySymbol = union(todayShortEntrySymbolOriginalAndMacd, todayShortEntrySymbolOriginalWithMacd, todayShortEntrySymbolMacdWithOriginal);
    // 5. 之前就進場標的取交集
    const nearDaysShortEntrySymbol = intersection(filteredNearDaysShortEntrySymbolMacd, filteredNearDaysShortEntrySymbolOriginal);
    const shortEntrySymbol = todayShortEntrySymbol.concat(nearDaysShortEntrySymbol);
    const data = useDailyRankResource({
        date: lastTradeString,
        volume_gte: 3000,
        volume_lte: 0,
        amount_gte: 0,
        amount_lte: 0,
        sort_by: 'volume',
        limit: 2000,
    }, 0);
    const allStock = data.data?.map(a => a.symbol);
    const symbolLong = state.showProAllSymboll === true
        ? longEntrySymbol
        : intersection(longEntrySymbol, allStock).slice(0, 6);
    const symbolShort = state.showProAllSymboll === true
        ? shortEntrySymbol
        : intersection(shortEntrySymbol, allStock).slice(0, 6);
    return (<styleds.container>
      <styleds.switchContent>
        <span>標的模式</span>
        <styleds.radioContent>
          <Radio label='全部' color='indigo' checked={state.showProAllSymboll === true} onChange={() => (asiajyeStore.showProAllSymboll = true)}/>
          <Radio label='精選' color='indigo' checked={state.showProAllSymboll === false} onChange={() => (asiajyeStore.showProAllSymboll = false)}/>
        </styleds.radioContent>
      </styleds.switchContent>
      <styleds.listTitle fill='#c85d4a'>多方標的({symbolLong?.length})</styleds.listTitle>
      <styleds.listContent fill='#c85d4a33'>
        <SimpleQuoteListInteractWithChart data={symbolLong ?? []} chart={store.charting} noAutoSubscribe={false}/>
      </styleds.listContent>
      <styleds.listTitle fill='#009900'>空方標的({symbolShort?.length})</styleds.listTitle>
      <styleds.listContent fill='#00990033'>
        <SimpleQuoteListInteractWithChart data={symbolShort ?? []} chart={store.charting} noAutoSubscribe={false}/>
      </styleds.listContent>
    </styleds.container>);
});
const styleds = {
    container: styled.div `
    ${fill_vertical_cross_center};
    display: grid;
    grid-template-rows: 30px 30px calc(50% - 45px) 30px calc(50% - 45px);
  `,
    switchContent: styled.div `
    ${fill_horizontal_cross_center};
    ${jc.spaceBetween};
    font-size: 14px;
    font-weight: 600;
    padding: 0 8px;
  `,
    listTitle: styled.div `
    ${fill_horizontal_all_center};
    background-color: ${props => props.fill};
    color: #ffffff;
    border-radius: 4px;
  `,
    listContent: styled.div `
    height: 100%;
    background-color: ${props => props.fill};
  `,
    radioContent: styled.div `
    ${fill_horizontal_cross_center};
    width: 50%;
    padding: 0 4px;
    justify-content: end;
    gap: 24px;
  `,
};
